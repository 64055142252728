import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import services from "../../services/services";


const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });
  const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());

  const accessToken = sessionStorage.getItem('accessToken');
  const [loggedUserId, setLoggedUserId] = useState(sessionStorage.getItem('userIdval'));

  if (loggedUserId || accessToken) {
    //alert("test")
    //return "";
    //loginCheck();
  }
  useEffect(() => {

    if (loggedUserId && accessToken) {
      //alert("test")
      //return "";
      loginCheck();
    }

    //loginCheck();
  }, []);

  const loginCheck = () => {
    
    //sessionStorage.setItem("userRole", "Admin");

    var loggedinUserRole = sessionStorage.getItem('userRole');
    if(loggedinUserRole == 'Employee' || loggedinUserRole == 'employee'){
       window.location.href='/dashboard';
       return;
    }else if(loggedinUserRole == 'Manager' || loggedinUserRole == 'manager'){
       window.location.href='/manager-dashboard';
       return;
    }else if(loggedinUserRole == 'Admin' || loggedinUserRole == 'admin'){
       window.location.href='/manager-dashboard';
       return;
    }else{
      window.location.href='/dashboard';
      return;
    }
  }
  const validateForm = () => {
    let errors = { email: "", password: "" };
    let isValid = true;

    if (!email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email.trim())) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (formErrors.email) {
      setFormErrors({ ...formErrors, email: "" });
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (formErrors.password) {
      setFormErrors({ ...formErrors, password: "" });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      confirmUserLogin();
    }
  };
  const confirmUserLogin = async () => {
    if (!validateForm()) {
      return;
    }

    // const requestBody = {
    //   email: email,
    //   password: password,
    // };

    const requestBody = {
      email: email.trim(),
      password: password.trim(),
    };

    try {
      const response = await fetch(`${apiEndPoint}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        const accessToken = data.access_token;
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("refreshToken", data.refresh_token);

        const decodedToken = jwtDecode(accessToken);
        const userRole = decodedToken.role;

        sessionStorage.setItem("userRole", userRole);
        sessionStorage.setItem("userIdval", decodedToken.sub);

        if (userRole === 3) {
         sessionStorage.setItem("userRole", "Employee");
          window.location.href = '/dashboard';
          return;
        } else if (userRole === 2) {
         sessionStorage.setItem("userRole", "Manager");
          window.location.href = '/manager-dashboard';
          return;
        } else if (userRole === 1) {
         sessionStorage.setItem("userRole", "Admin");
         sessionStorage.setItem("adminCompany", '1');

         /*
          await services.getCompanyListInital(1,accessToken).then((res) => {
            alert(JSON.stringify(res));
            if (res.data.type === "success") {
              var fullcompanyList = res.data.data;
              //setCompaniesFilterData(res.data.data);.
              for (var i = 0; i < fullcompanyList.length; i++) {
                if (fullcompanyList[i].id === res.data.data.company_id) {
                  //setUserCompanyFilterData(fullcompanyList[i].company_name);
                  sessionStorage.setItem("adminCompany", fullcompanyList[i].id);
                }
              }
            }


          });
          */




          window.location.href = '/admin-dashboard';
          return;
        }
      } else {
        setErrorMessage(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login error, please try again.');
    }
  };

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="d-flex justify-content-center py-5">
              <div className="loginwrap border p-4">
                <div className="text-center d-grid justify-content-center mb-4">
                  <img src="images/timesheet-logo.png" alt="" className="m-auto" />
                  <h5 className="text-center my-2">Sign In</h5>
                </div>

                <div className="mb-3">
                  <input
                    type="email"
                    className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                    placeholder="Work Email"
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
                </div>
                <div className="mb-3 ">
                  <button className="btn btn-primary mx-0 w-100" onClick={confirmUserLogin}>LOGIN</button>
                </div>
                {errorMessage && <div className="alert alert-danger py-1 px-2">{errorMessage}</div>}
                <div className="w-100 text-center">
                  <a href="/forgot-password">Forgot Password</a>
                </div>
                <div className="d-flex justify-content-center gap-3 tc mt-2">
                  <a href="#">Terms of service</a> <span>|</span> <a href="#">Privacy policy</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default LoginComponent;
