import React,{useEffect, useState} from "react";
import services from "../../services/services";
import $ from 'jquery';

const Login = () => {

const [usersListInfoforLogin, setUsersListInfoforLogin] = useState([]);
const [AdminsListInfoforLogin, setAdminsListInfoforLogin] = useState([]);
const [ManagersListInfoforLogin, setManagersListInfoforLogin] = useState([]);
const [companyFilterData, setCompaniesFilterData] = useState([]);

    useEffect(() => {
      sessionStorage.setItem("userRole", "");
      sessionStorage.setItem("userIdval", "");
      sessionStorage.setItem("userCompanyName", "");
      sessionStorage.setItem("userCompanyId", "");
      sessionStorage.setItem("userProfileAvatar", "");
      sessionStorage.setItem("userFullName", "");
      sessionStorage.setItem("adminCompany","");
      getAllEmployees();
      getAdminUsers();
      getManagerUsers();
    }, []);

    const confirmUserLogin = () => {
      //sessionStorage.setItem("userRole", "Admin");

      var loggedinUserRole = sessionStorage.getItem('userRole');
      if(loggedinUserRole == 'Employee' || loggedinUserRole == 'employee'){
         window.location.href='/dashboard';
         return;
      }else if(loggedinUserRole == 'Manager' || loggedinUserRole == 'manager'){
         window.location.href='/manager-dashboard';
         return;
      }else if(loggedinUserRole == 'Admin' || loggedinUserRole == 'admin'){
         window.location.href='/manager-dashboard';
         return;
      }else{
         window.location.href='/login';
         return;
      }
    }

    function getAllEmployees(){
      const requestObject =
      {
          "roleId": ["3"],
          "status": '1',
          "companyId": '',
          "pageNumber": 1,
          "pageSize": 1000
        }
      console.log(requestObject); //return false;
      var accessTokenIn=sessionStorage.getItem("accToken");
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': "Bearer "+accessTokenIn
      //   },
      //   body: JSON.stringify(requestObject)
      // };
      services.getUsersByRole(requestObject).then((res) => {
          console.log(res);
          setUsersListInfoforLogin(res.data);
      });

    }



    const empconfirmUserLogin = () => {
      var useridselected = $('#userlistval').val();
      sessionStorage.setItem("userRole", "Employee");
      sessionStorage.setItem("userIdval", useridselected);
      getCompanyId(useridselected);
/*
      var loggedinUserRole = sessionStorage.getItem('userRole');
      if(loggedinUserRole == 'Employee' || loggedinUserRole == 'employee'){
         window.location.href='/dashboard';
      }
      if(loggedinUserRole == 'Manager' || loggedinUserRole == 'manager'){
         window.location.href='/manager-dashboard';
      }
      if(loggedinUserRole == 'Admin' || loggedinUserRole == 'admin'){
         window.location.href='/manager-dashboard';
      } */

      //console.log(loggedinUserRole);
    }

    const getAdminUsers = () => {
      //load admin
      const requestObject =
      {
          "roleId": ["1"],
          "status": '1',
          "companyId": '',
          "pageNumber": 1,
          "pageSize": 10
        }
      //console.log(requestObject); //return false;
      var accessTokenIn=sessionStorage.getItem("accToken");
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': "Bearer "+accessTokenIn
      //   },
      //   body: JSON.stringify(requestObject)
      // };
      services.getUsersByRole(requestObject).then((res) => {

         setAdminsListInfoforLogin(res.data);
      });
}

    const adminconfirmUserLogin = () => {
      var adminidselected = $('#adminlistval').val();

      sessionStorage.setItem("userRole", "Admin");
      sessionStorage.setItem("userIdval", adminidselected);
      getCompanyId(adminidselected);

      // var useridselected = $('#userlistval').val();
      // sessionStorage.setItem("userIdval", useridselected);
/*
      var loggedinUserRole = sessionStorage.getItem('userRole');
      if(loggedinUserRole == 'Employee' || loggedinUserRole == 'employee'){
         window.location.href='/dashboard';
      }
      if(loggedinUserRole == 'Manager' || loggedinUserRole == 'manager'){
         window.location.href='/manager-dashboard';
      }
      if(loggedinUserRole == 'Admin' || loggedinUserRole == 'admin'){
         window.location.href='/manager-dashboard';
      } */


    }

    const getManagerUsers = () => {
      //load admin
      const requestObject =
      {
          "roleId": ["2"],
          "status": '1',
          "companyId": '',
          "pageNumber": 1,
          "pageSize": 10
        }
      //console.log(requestObject); //return false;
      var accessTokenIn=sessionStorage.getItem("accToken");
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': "Bearer "+accessTokenIn
      //   },
      //   body: JSON.stringify(requestObject)
      // };
      services.getUsersByRole(requestObject).then((res) => {

         setManagersListInfoforLogin(res.data);
      });
}

    const managerconfirmUserLogin = () => {
      var manageridselected = $('#managerlistval').val();
      sessionStorage.setItem("userRole", "Manager");
      sessionStorage.setItem("userIdval", manageridselected);
      getCompanyId(manageridselected);
    }

    const getCompanyId = (logid) => {
      services.getCompanyList(1).then((res) => {
          if(res.data.type === "success"){
              var fullcompanyList = res.data.data;
              setCompaniesFilterData(res.data.data);

              services.getUsersInfoById(logid).then((res) => {
                  if(res.data.type === 'success'){
                      for(var i=0; i < fullcompanyList.length; i++){
                          if(fullcompanyList[i].id === res.data.data.company_id){
                              //setUserCompanyFilterData(fullcompanyList[i].company_name);
                              sessionStorage.setItem("userCompanyName", fullcompanyList[i].company_name);
                              sessionStorage.setItem("userCompanyId", fullcompanyList[i].id);


                              var loggedinUserRole = sessionStorage.getItem('userRole');
                              if(loggedinUserRole == 'Employee' || loggedinUserRole == 'employee'){
                                 window.location.href='/dashboard';
                              }else if(loggedinUserRole == 'Manager' || loggedinUserRole == 'manager'){
                                 window.location.href='/manager-dashboard';
                              }else if(loggedinUserRole == 'Admin' || loggedinUserRole == 'admin'){
                                 sessionStorage.setItem("adminCompany", fullcompanyList[i].id);
                                 window.location.href='/admin-dashboard';
                              }else{
                                 window.location.href='/login';
                              }
                          }
                      }
                  }
              });

          }
      });
  }

  return(
  <>
   <main>


         <section>
            <div class="container">
            <div class="d-flex justify-content-center my-5">
               <div class="loginwrap border p-4">
                     <div class="text-center d-grid justify-content-center mb-4">
                        <img src="images/timesheet-logo.png" alt="" class="m-auto"/>
                        <h5 class="text-center">Sign In</h5>
                     </div>
                     {/*<div class="mb-3">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter work Email"/>
                     </div>
                     <div class="mb-3">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password"/>
                     </div> */}
                     {/*<div class="mb-3 ">
                        <button class="btn btn-primary w-100" onClick={confirmUserLogin}>LOGIN</button>
                     </div> */}

                     <div class="mb-2">
                        <div class="form-group mb-2">
                           <label for="recipient-name" class="col-form-label">User </label>
                           <select class={`form-select`} id="userlistval" aria-label="Default select example">
                              {usersListInfoforLogin.map((userData, userIndex) => (
                                 <option value={userData.id}>{userData.first_name+' '+userData.last_name}</option>
                              ))}
                           </select>
                     </div>

                        <button class="btn btn-primary mx-0 w-100 m-0" onClick={empconfirmUserLogin}>USER LOGIN</button>

                     </div>

                     <div class="mb-2"></div>

                     <div class="mb-2">

                     <div class="form-group mb-2">
                           <label for="recipient-name" class="col-form-label">Admin </label>
                           <select class={`form-select`} id="adminlistval" aria-label="Default select example">
                              {AdminsListInfoforLogin.map((adminData, adminIndex) => (
                                 <option value={adminData.id}>{adminData.first_name+' '+adminData.last_name}</option>
                              ))}
                           </select>
                     </div>
                        {/* <button class="btn btn-primary w-100 m-0" onClick={adminconfirmUserLogin}>ADMIN LOGIN</button> */}

                     </div>

                     <div class="mb-2">
                        <div class="form-group mb-2">
                              <label for="recipient-name" class="col-form-label">Manager </label>
                              <select class={`form-select`} id="managerlistval" aria-label="Default select example">
                                 {ManagersListInfoforLogin.map((managerData, managerIndex) => (
                                    <option value={managerData.id}>{managerData.first_name+' '+managerData.last_name}</option>
                                 ))}
                              </select>
                        </div>
                        <button class="btn btn-primary w-100 m-0" onClick={managerconfirmUserLogin}>MANAGER LOGIN</button>
                     </div>
                     <div class="w-100 text-center">
                        <a href="/forgot-password">Forgot Password</a>
                     </div>
                     <div class="d-flex justify-content-center gap-3 tc mt-2">
                        <a href="#">Terms of service</a> <span>|</span> <a href="#">Privacy policy</a>
                     </div>
               </div>
            </div>
            </div>
         </section>

      </main>
  </>
)};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
